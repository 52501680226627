<template>
    <div>
        <q-card class="my-card fixed-center" style="width: 500px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6 text-weight-light">Field Services App</div>
                <q-separator spaced />
                <q-form
                    @submit="login()"
                    class="q-gutter-md q-mt-sm"
                >
                <div class="q-ma-sm row">
                    <div class="q-ma-sm col-12">
                    <q-input
                        v-model="user.email"
                        :dense="true"
                        label="Email"
                        type="email"
                        outlined
                    />
                    </div>
                    <div class="q-ma-sm col-12">
                    <q-input
                        v-model="user.password"
                        :dense="true"
                        label="Password"
                        type="password"
                        outlined
                    />
                    </div>
                </div>
                    <div>
                        <q-btn label="Login" type="submit" color="dark"/>
                        <q-btn label="Register" class="q-ml-sm" type="submit" color="dark" to="/register"/>
                    </div>
                </q-form>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
import axios from '../../axios'
import router from '../../router/index'
import { useQuasar } from 'quasar'

export default {
    data() {
        return {
            layout: false,
            user: {},
            notify: useQuasar(),
        }
    },
    methods: {
        async login() {
            axios
                .post('/api/Auth/Login', this.user)
                .then(response => {
                    localStorage.setItem("access_token", response.data.token)
                    localStorage.setItem("username", response.data.userName)
                    localStorage.setItem("roles", response.data.roles)
                    this.notify.notify({
                        message: 'Login Successful',
                        color: 'green'
                    })
                    router.push('/')
                })
                .catch(error => {
                    this.notify.notify({
                        message: error.response.data.message,
                        color: 'red'
                    })
                })
        },
    },
}
</script>
